.footer {
    background-color: var(--bluishBlack);
    text-align: center;

}

.footer--container {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:25px
}

.footer p {
    margin: 0;
    color: var(--Grey);
}

.footer a {
    text-decoration: none;
    color: var(--lightGrey);
}

.footer span {
    color: var(--Grey);
}