
/* Colour Palette 
Black - #1E2023
Bluish Black - #161B22
White - #FEFFFE
Pink - #FE206F
Cyan - #50C8CF
Light Grey - #DEDEDF
Button Grey - #DDDDDD
*/

:root {
  --black: #121315;
  --bluishBlack: #0d1014;
  --pink: #9d9d9d;
  --cyan: #42f78a;
  --white: #FEFFFE;
  --lightGrey: #DEDEDF;
  --Grey: #4b4b4b;
  --neutralGrey: gray;
  --darkGrey: #1c1c1c;
  --btnGrey: #DDDDDD;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto Mono', monospace;
  }
  
  body,
  html {
    margin: 0;
    min-height: 100vh;
    
  }
  
  h1 {
    font-size: 50px;
  }

  h3 {
    padding-bottom: 20px;
  }

  p {
    font-size: 20px;
  }
  
  a {
    text-decoration: none;
    color: var(--white);
  }

  a:hover {
    color: var(--cyan) !important;
  }

  button {
    color: var(--black);
  }

  .text__center {
    text-align: center;
  }
  
  .color__cyan {
    color: var(--cyan) !important;
  }
  
  .color__pink {
    color: var(--pink) !important;
  }

  /* Sections */

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section--container {
    padding-top: 50px;
}

  .section--title {
    text-align: center;
  }

  .text--subheading {
    border-left: var(--cyan) solid 5px;
    padding-left: 10px;
  }

  .text--highlight__cyan {
    background-color: var(--cyan);
    color: var(--darkGrey);
    display: inline-block;
    padding: 5px;
    margin-bottom: 20px;
  }
  .text--highlight__pink {
    background-color: var(--white);
    color: var(--black);
    display: inline-block;
    border: solid var(--black) 1.5px;
    padding: 5px;
    margin-bottom: 0px;
  }

  .modal {
    border-radius: 0 !important;
  }

  /* Mobile */
  @media (max-width: 480px) {
    .text__big {
      font-size: 64px;
    }

    .section--title {
      padding: 25px;
    }

    .projectCard--tag--text {
    }

    .tags {
      font-size: small;
    }
  }