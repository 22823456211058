@import "../css/animations.css";
.hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

canvas {
  background-color: #1E2023;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100vh;
}

.canvas--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.hero__main {
  height: 70vh;
  color: var(--white);
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero__text {
  border-left: var(--cyan) solid 10px;
}

.hero__text h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  font-weight: 300;
}

.hero__scroller {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white);
  animation-name: bounce;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.text__big {
  font-size: 128px;
}

.name--style {
  background-color: var(--cyan);
  color: var(--darkGrey);
  font-weight: 500;
  font-size: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 480px) {
  .text__big {
    font-size: 64px;
  }
}