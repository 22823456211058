.filterBar  {
    display: flex;
    justify-content: left;
    gap: 10px;
    padding-left: 100px;
    padding-right: 100px;
    overflow-x: auto;
    white-space: nowrap;
}

.filterBar--button {
    border: none;
    background-color: var(--lightGrey);
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    border: solid 2px var(--lightGrey);
}

.filterBar--button:hover {
    border: none;
    background-color: #b9b9b9;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    border: solid 2px #b9b9b9;
}

.filterBar--button__active {
    border: none;
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    background-color: var(--cyan);
    color: var(--darkGrey);
}

.project--cards--container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.projectCard--tag--text {
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width: 600px) {
    .filterBar::-webkit-scrollbar {
        display: none;
    }

    .filterBar {
        transition: transform 0.5s ease-in-out;
    }

    .filterBar:hover {
        transform: translateX(-5px);
    }
}
