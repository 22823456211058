.section--subheading__technologies {
    margin-top: 500px;
}

.about--item {
    width: 400px;
}

.technology--container {
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
}
